(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/debug-ui/assets/javascripts/debug-ui.js') >= 0) return;  svs.modules.push('/components/components/debug-ui/assets/javascripts/debug-ui.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  if (!svs.core.detect.feature('ft-debug-ui')) {
    return;
  }

  var components = [
    {
      name: 'Accordion',
      path: 'ui/accordion',
      selector: '.accordion',
      settingsPrefix: 'accordion'
    },
    {
      name: 'Badge',
      path: 'ui/badge',
      selector: '.badge',
      settingsPrefix: 'badge'
    },
    {
      name: 'Bank',
      path: 'ui/bank',
      selector: '.bank',
      settingsPrefix: 'bank'
    },
    {
      name: 'Brands',
      path: 'ui/brands',
      selector: '.brand',
      settingsPrefix: 'brand'
    },
    {
      name: 'Brands torned',
      path: 'ui/brands-torned',
      selector: '.brands-torned',
      settingsPrefix: 'brands-torned'
    },
    {
      name: 'Button',
      path: 'ui/button',
      selector: '.btn',
      settingsPrefix: 'btn'
    },
    {
      name: 'Button bet',
      path: 'ui/button-bet',
      selector: '.btn-bet',
      settingsPrefix: 'btn-bet'
    },
    {
      name: 'Button group',
      path: 'ui/button-group',
      selector: '.btn-group',
      settingsPrefix: 'btn-group'
    },
    {
      name: 'Card',
      path: 'ui/card',
      selector: '.card',
      settingsPrefix: 'card'
    },
    {
      name: 'Dots',
      path: 'ui/dots',
      selector: '.dots',
      settingsPrefix: 'dots'
    },
    {
      name: 'Drawer',
      path: 'ui/drawer',
      selector: '.drawer',
      settingsPrefix: 'drawer'
    },
    {
      name: 'Dropdown',
      path: 'ui/dropdown',
      selector: '.dropdown',
      settingsPrefix: 'dropdown'
    },
    {
      name: 'Elevation',
      path: 'ui/elevation',
      selector: '[class*=elevation-]',
      settingsPrefix: 'elevation'
    },
    {
      name: 'Form - input',
      path: 'ui/form',
      selector: '.standard-input',
      settingsPrefix: 'input'
    },
    {
      name: 'Form - input',
      path: 'ui/form',
      selector: '.std-input',
      settingsPrefix: 'std-input'
    },
    {
      name: 'Form - select',
      path: 'ui/form',
      selector: '.select',
      settingsPrefix: 'select'
    },
    {
      name: 'Form - checkbox',
      path: 'ui/form',
      selector: '.form-toggle-checkbox + .form-label',
      settingsPrefix: 'form-label'
    },
    {
      name: 'Form - radio',
      path: 'ui/form',
      selector: '.form-toggle-radio:not(.form-toggle-button) + .form-label',
      settingsPrefix: 'form-label'
    },
    {
      name: 'Form - radio button',
      path: 'ui/form',
      selector: '.form-toggle-radio.form-toggle-button + .form-label',
      settingsPrefix: 'form-label'
    },
    {
      name: 'Grid',
      path: 'ui/grid',
      selector: '[class*=col-]',
      settingsPrefix: 'col'
    },
    {
      name: 'Grid',
      path: 'ui/grid',
      selector: '[class*=grid-flex-col]',
      settingsPrefix: 'grid-flex'
    },
    {
      name: 'Icon',
      path: 'ui/icon',
      selector: '.icon, .icon-default',
      settingsPrefix: 'icon'
    },
    {
      name: 'Icon button',
      path: 'ui/icon-button',
      selector: '.icon-button',
      settingsPrefix: 'icon-button'
    },
    {
      name: 'Infobar',
      path: 'ui/infobar',
      selector: '.infobar',
      settingsPrefix: 'infobar'
    },
    {
      name: 'Items menu',
      path: 'ui/items-menu',
      selector: '.items-menu-wrapper',
      settingsPrefix: 'items-menu-wrapper'
    },
    {
      name: 'List group',
      path: 'ui/list-group',
      selector: '.list-group',
      settingsPrefix: 'list-group'
    },
    {
      name: 'Media object',
      path: 'ui/media-object',
      selector: '.media',
      settingsPrefix: 'media'
    },
    {
      name: 'Navigation indicator',
      path: 'ui/navigation-indicator',
      selector: '.navigation-indicator',
      settingsPrefix: 'navigation-indicator'
    },
    {
      name: 'Panel',
      path: 'ui/panel',
      selector: '.panel',
      settingsPrefix: 'panel'
    },
    {
      name: 'Paper',
      path: 'ui/paper',
      selector: '.paper',
      settingsPrefix: 'paper'
    },
    {
      name: 'Ribbon',
      path: 'ui/ribbon',
      selector: '.ribbon',
      settingsPrefix: 'ribbon'
    },
    {
      name: 'Sausage',
      path: 'ui/sausage',
      selector: '.sausage',
      settingsPrefix: 'sausage'
    },
    {
      name: 'Step indicator',
      path: 'ui/step-indicator',
      selector: '.step-indicator',
      settingsPrefix: 'step-indicator'
    },
    {
      name: 'Table',
      path: 'ui/table',
      selector: '.table',
      settingsPrefix: 'table'
    },
    {
      name: 'Typography',
      path: 'ui/typography',
      selector: '.f-content',
      settingsPrefix: 'f-content'
    },
    {
      name: 'Typography - heading',
      path: 'ui/typography',
      selector: '.f-content h1, .f-content h2, .f-content h3, .f-content h4, .f-content .h1, .f-content .h2, .f-content .h3, .f-content .h4',
      settingsPrefix: ''
    },
    {
      name: 'Typography - heading',
      path: 'ui/typography',
      selector: '.f-100, .f-200, .f-300, .f-400, .f-500, .f-550, .f-600, .f-700, .f-800, .f-900',
      settingsPrefix: 'f-'
    },
    {
      name: 'Dialog',
      path: 'ui/dialog',
      selector: '.dialog-item',
      settingsPrefix: 'dialog'
    },
    {
      name: 'Dialog contextual',
      path: 'ui/dialog-contextual',
      selector: '.dialog-contextual-item',
      settingsPrefix: 'dialog'
    },
    {
      name: 'Tab',
      path: 'components/tab',
      selector: '.tab',
      settingsPrefix: 'tab'
    },
    {
      name: 'Tabs',
      path: 'components/tabs',
      selector: '.tabs-tabs',
      settingsPrefix: 'tabs'
    },
    {
      name: 'Tab view',
      path: 'app/tab-view',
      selector: '.rsNav',
      settingsPrefix: 'rs'
    }
  ];
  var isComponentDebugEnabled = false;
  var gutterClass = 'gutter-xs-1 gutter-md-2';
  var spacingClass = 'padding-xs-h-1 padding-md-h-2';

  function initToolbar() {
    $('body').append($('<div class="debug-ui-toolbar">' +
      '<div class="debug-ui-toolbar-tools padding-xs-1">' +
      '<h2 class="f-800 js-debug-ui-ignore">Inspect</h2>' +
      svs.ui.button.partials.button({
        size: '100',
        class: 'js-debug-ui-toolbar-disable debug-ui-toolbar-disable js-debug-ui-ignore',
        transparent: true,
        inverted: true,
        title: 'Disable',
        linkTitle: 'Disable inspect tool'
      }) +
      '<div class="margin-xs-bottom-1">' +
      svs.ui.form.partials.checkbox({
        value: 'components',
        label: 'Components',
        branding: 'inverted',
        id: 'debug-ui-toolbar-tool-components',
        class: 'js-debug-ui-toolbar-tool-components',
        labelClass: 'js-debug-ui-ignore'}) +
      '</div>' +
      '<div class="margin-xs-bottom-1">' +
      svs.ui.form.partials.checkbox({
        value: 'measure',
        label: 'Measure',
        branding: 'inverted',
        id: 'debug-ui-toolbar-tool-measure',
        class: 'js-debug-ui-toolbar-tool-measure',
        labelClass: 'js-debug-ui-ignore'}) +
      '</div>' +
      '<div class="margin-xs-bottom-1">' +
      svs.ui.form.partials.checkbox({
        value: 'grid',
        label: 'Grid',
        branding: 'inverted',
        id: 'debug-ui-toolbar-tool-grid',
        class: 'js-debug-ui-toolbar-tool-grid',
        labelClass: 'js-debug-ui-ignore',
        title: 'asdsad'}) +
      svs.ui.form.partials.control_group({formv2: true,
        content: svs.ui.form.partials.select({branding: 'inverted', id: 'debug-ui-toolbar-tool-grid-gutter', class: 'js-debug-ui-toolbar-tool-grid-gutter js-debug-ui-ignore', formv2: true,
          content: svs.ui.form.partials.select_option({content: 'Gutter', disabled: true, selected: true}) +
          svs.ui.form.partials.select_option({content: '0', value: '0'}) +
          svs.ui.form.partials.select_option({content: '6px', value: '6'}) +
          svs.ui.form.partials.select_option({content: '12px', value: '1'}) +
          svs.ui.form.partials.select_option({content: '24px', value: '2'}) +
          svs.ui.form.partials.select_option({content: '36px', value: '3'}) +
          svs.ui.form.partials.select_option({content: '48px', value: '4'})
        })}) +
        svs.ui.form.partials.control_group({formv2: true,
          content: svs.ui.form.partials.select({branding: 'inverted', id: 'debug-ui-toolbar-tool-grid-spacing', class: 'js-debug-ui-toolbar-tool-grid-spacing js-debug-ui-ignore', formv2: true,
            content: svs.ui.form.partials.select_option({content: 'Outer spacing', disabled: true, selected: true}) +
                svs.ui.form.partials.select_option({content: '0', value: '0'}) +
                svs.ui.form.partials.select_option({content: '6px', value: '6'}) +
                svs.ui.form.partials.select_option({content: '12px', value: '1'}) +
                svs.ui.form.partials.select_option({content: '24px', value: '2'}) +
                svs.ui.form.partials.select_option({content: '36px', value: '3'}) +
                svs.ui.form.partials.select_option({content: '48px', value: '4'})
          })}) +
      '</div>' +
      '</div>' +
      '<div class="js-debug-ui-toolbar-breakpoint debug-ui-toolbar-breakpoint">xs</div>' +
      '<div class="js-debug-ui-toolbar-toggle debug-ui-toolbar-toggle">&lt;/&gt;</div>' +
      '</div>'));

    $('.js-debug-ui-toolbar-toggle').click(function() {
      var toggle = $(this).parent('.debug-ui-toolbar');
      if (toggle.hasClass('debug-ui-toolbar-open')) {
        toggle.removeClass('debug-ui-toolbar-open');
      } else {
        toggle.addClass('debug-ui-toolbar-open');
      }
    });

    $('.js-debug-ui-toolbar-disable').click(function() {
      svs.core.module.load('/cl/components/dialog', null, function() {
        var dialog = new svs.components.dialog.Confirm({
          branding: svs.components.dialog.branding.NEUTRAL,
          icon: 'information',
          title: 'Disable inspect',
          message: [
            {
              type: svs.components.dialog.message.TEXT,
              text:
                'This will disable the inspect tool. To enable inspect tool again please visit the <a href="/styleguide">styleguide</a>.'
            }
          ],
          actions: [
            {
              title: 'Cancel',
              callback: function() {
                dialog.close();
              }
            },
            {
              title: 'Disable',
              callback: function() {
                window.location = '/ft/ft-debug-ui/reset?returnUrl=' + window.location.pathname;
              }
            }
          ]
        });

        svs.components.dialog.api.add(dialog);
      });
    });

    $('.js-debug-ui-toolbar-tool-grid-gutter').change(function() {
      var value = $(this).val();
      var gridOverlay = $('.grid-overlay');
      gridOverlay.removeClass('gutter-xs gutter-xs-1 gutter-xs-2 gutter-xs-3 gutter-xs-4 gutter-md-2');
      gutterClass = '';

      if (value !== '0') {
        if (value === '6') {
          gutterClass = 'gutter-xs';
        } else {
          gutterClass = 'gutter-xs-' + value;
        }

        gridOverlay.addClass(gutterClass);
      }
    });

    $('.js-debug-ui-toolbar-tool-grid-spacing').change(function() {
      var value = $(this).val();
      var gridOverlay = $('.grid-overlay');
      gridOverlay.removeClass('padding-xs-h padding-xs-h-1 padding-xs-h-2 padding-xs-h-3 padding-xs-h-4 padding-md-h-2');
      spacingClass = '';

      if (value !== '0') {
        if (value === '6') {
          spacingClass = 'padding-xs-h';
        } else {
          spacingClass = 'padding-xs-h-' + value;
        }

        gridOverlay.addClass(spacingClass);
      }
    });

    $('.js-debug-ui-toolbar-tool-components').change(function(e) {
      var componentCheckbox = $(e.currentTarget);

      if (componentCheckbox.prop('checked')) {
        isComponentDebugEnabled = true;
        addDebugCSSClasses();
      } else {
        isComponentDebugEnabled = false;
        addDebugCSSClasses();
      }
    });

    $('.js-debug-ui-toolbar-tool-grid').change(function(e) {
      var componentCheckbox = $(e.currentTarget);

      if (componentCheckbox.prop('checked')) {
        var grid = $('<div class="grid-row"><div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div>' +
            '<div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div>' +
            '<div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div>' +
            '<div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div><div class="col-xs-1 js-debug-ui-ignore"></div>' +
            '<div class="col-xs-1 js-debug-ui-ignore"></div></div>');
        $('body').append($('<div />').addClass('grid-overlay ' + gutterClass + ' ' + spacingClass).append(grid));
        $('.js-debug-ui-toolbar-breakpoint').addClass('debug-ui-toolbar-breakpoint-show');
      } else {
        $('.grid-overlay').remove();
        $('.js-debug-ui-toolbar-breakpoint').removeClass('debug-ui-toolbar-breakpoint-show');
      }
    });

    $('.js-debug-ui-toolbar-tool-measure').change(function(e) {
      var componentCheckbox = $(e.currentTarget);

      if (componentCheckbox.prop('checked')) {
        $('body')
          .append($('<div />').addClass('overlay-line-x overlay-line-x-1 js-overlay-line-x-1'))
          .append($('<div />').addClass('overlay-line-x overlay-line-x-2 js-overlay-line-x-2'))
          .append($('<div />').addClass('overlay-line-y overlay-line-y-1 js-overlay-line-y-1'))
          .append($('<div />').addClass('overlay-line-y overlay-line-y-2 js-overlay-line-y-2'))
          .append($('<div />').addClass('overlay-line-coordinates'))
          .on('mousemove', cursorMove)
          .on('mousedown', cursorDown)
          .on('mouseup', cursorUp)
          .css('cursor', 'crosshair');
      } else {
        $('.overlay-line-x').remove();
        $('.overlay-line-y').remove();
        $('.overlay-line-coordinates').remove();
        $('body').off('mousemove', cursorMove);
        $('body').off('mousedown', cursorDown);
        $('body').off('mouseup', cursorUp);
        $('body').css('cursor', 'auto');
      }
    });

    $('.js-debug-ui-toolbar-breakpoint').html(svs.core.detect.breakpoint.current);
    svs.core.detect.breakpoint.on('change', function() {
      $('.js-debug-ui-toolbar-breakpoint').html(svs.core.detect.breakpoint.current);
    });
  }

  var isDrawingEnabled = false;
  var div;
  var startX;
  var startY;

  function cursorDown(e) {
    startX = parseInt(e.pageX, 10);
    startY = parseInt(e.pageY, 10);

    if (div) {
      div.remove();
      div = null;
    }

    isDrawingEnabled = true;
  }

  function cursorUp(e) {
    if (isDrawingEnabled) {
      isDrawingEnabled = false;
      e.preventDefault();
    }
  }

  function cursorMove(e) {
    var $window = $(window);
    var width = $window.width();
    var height = $window.height();

    if (isDrawingEnabled) {
      if (!div && (parseInt(e.pageY, 10) > startY + 2 || parseInt(e.pageY, 10) < startY - 2 || parseInt(e.pageX, 10) < startX - 2 || parseInt(e.pageX, 10) > startX + 2)) {
        div = $('<div class="overlay-line-measure"><div class="js-overlay-line-measure-size overlay-line-measure-size"></div></div>').appendTo('body');
        div.css({
          top: startY,
          left: startX
        });
      }

      if (div) {
        e.preventDefault();

        div.height(Math.abs(parseInt(e.pageY, 10) - parseInt(div.css('top'), 10)) - 1);
        div.width(Math.abs(parseInt(e.pageX, 10) - parseInt(div.css('left'), 10)) - 1);

        if (parseInt(e.pageY, 10) < startY) {
          div.css({
            top: e.pageY
          });
          div.height(Math.abs(parseInt(e.pageY, 10) - startY) - 1);
        }
        if (parseInt(e.pageX, 10) < startX) {
          div.css({
            left: e.pageX
          });
          div.width(Math.abs(parseInt(e.pageX, 10) - startX) - 1);
        }

        div.find('.js-overlay-line-measure-size').html(div.outerWidth() + ' x ' + div.outerHeight());
      }
    }

    $('.js-overlay-line-x-1').css({
      top: e.pageY - $(document).scrollTop(),
      right: width - e.pageX + 2
    });
    $('.js-overlay-line-x-2').css({
      top: e.pageY - $(document).scrollTop(),
      left: e.pageX + 2
    });

    $('.js-overlay-line-y-1').css({
      left: e.pageX - $(document).scrollLeft(),
      bottom: height - e.pageY + 2
    });

    $('.js-overlay-line-y-2').css({
      left: e.pageX - $(document).scrollLeft(),
      top: e.pageY + 2
    });

    $('.overlay-line-coordinates')
      .css(
        'transform',
        'translate3d(' +
          (e.pageX + 1 - $(document).scrollLeft()) +
          'px, ' +
          (e.pageY + 1 - $(document).scrollTop()) +
          'px, 0)'
      )
      .text(e.pageX + ' x ' + e.pageY);
  }

  function addDebugCSSClasses() {
    if (isComponentDebugEnabled) {
      for (var i = 0, length = components.length; i < length; i++) {
        var component = components[i];
        const index = i.toString();

        $(component.selector)
          .not('.js-debug-ui-ignore')
          .addClass('js-debug-ui-component debug-ui-component')
          .each(function() {
            var debugAttr = $(this).attr('data-debug-ui-component');

            if (debugAttr) {
              debugAttr = debugAttr.split(',');

              if (debugAttr.indexOf(index) < 0) {
                debugAttr.push(index);
                debugAttr = debugAttr.join(',');
              }
            } else {
              debugAttr = index;
            }

            $(this).attr('data-debug-ui-component', debugAttr);
          });
      }
    } else {
      $('.js-debug-ui-component').removeClass('js-debug-ui-component debug-ui-component');
    }
  }

  var oldElement;
  var currentComponentIndex = 0;
  var currentComponents = [];
  var currentComponentElement;

  function initComponentPopup() {
    $('body').on('mouseover', '.js-debug-ui-component', function(e) {
      e.stopPropagation();
      clearTimeout(componentPopupIndex);

      if ($(this) !== oldElement) {
        oldElement = currentComponentElement;
        currentComponentIndex = 0;
        currentComponents = $(this).attr('data-debug-ui-component').split(',');
        currentComponentElement = $(this);

        openComponentPopup(currentComponentElement, components[parseInt(currentComponents[currentComponentIndex], 10)], currentComponents.length > 1);
      }
    });

    $('body').on('mouseout', '.js-debug-ui-component', function() {
      closeComponentPopup();
    });

    $('body').on('mouseover', '.debug-ui-info-popup', function() {
      clearTimeout(componentPopupIndex);
    });

    $('body').on('mouseout', '.debug-ui-info-popup', function() {
      closeComponentPopup();
    });

    $('body').on('click', '.js-debug-ui-component-next', function() {
      if (currentComponentIndex + 1 < currentComponents.length) {
        currentComponentIndex++;
        openComponentPopup(currentComponentElement, components[parseInt(currentComponents[currentComponentIndex], 10)], true);
      }

      $('.js-debug-ui-component-prev').prop('disabled', currentComponentIndex === 0);
      $('.js-debug-ui-component-next').prop('disabled', currentComponentIndex === currentComponents.length - 1);
    });

    $('body').on('click', '.js-debug-ui-component-prev', function() {
      if (currentComponentIndex - 1 >= 0) {
        currentComponentIndex--;
        openComponentPopup(currentComponentElement, components[parseInt(currentComponents[currentComponentIndex], 10)], true);
      }

      $('.js-debug-ui-component-prev').prop('disabled', currentComponentIndex === 0);
      $('.js-debug-ui-component-next').prop('disabled', currentComponentIndex === currentComponents.length - 1);
    });

    $('body').append($('<div></div>').addClass('debug-ui-info-popup').hide());
  }

  var componentPopupIndex;

  function closeComponentPopup() {
    clearTimeout(componentPopupIndex);
    componentPopupIndex = setTimeout(function() {
      $('.debug-ui-info-popup').hide();
    }, 300);
  }

  function rgb2hex(rgb) {
    if (rgb.startsWith('rgba')) {
      return rgb.replace('0.', '.');
    }

    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    function hex(x) {
      return ('0' + parseInt(x, 10).toString(16)).slice(-2);
    }
    return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
  }

  function getColorName(color) {
    color = rgb2hex(color);

    if (svs.components.debug_ui && svs.components.debug_ui.data && svs.components.debug_ui.data.colors) {
      var colorName = svs.components.debug_ui.data.colors[color];

      if (colorName) {
        color = colorName;
      }
    }

    return color;
  }

  function getComponentMetaData(element, component, hasMultipleComponents) {
    var buttons = `<div class="debug-ui-component-navigate">`;
    buttons += svs.ui.button.partials.button({
      size: '100',
      class: 'js-debug-ui-component-prev debug-ui-component-prev js-debug-ui-ignore',
      transparent: true,
      circle: true,
      thin: true,
      disabled: true,
      title: svs.ui.icon.partials.icon({ icon: 'menu-left', class: 'js-debug-ui-ignore' }),
      linkTitle: 'Previous component'
    });
    buttons += svs.ui.button.partials.button({
      size: '100',
      class: 'js-debug-ui-component-next debug-ui-component-next js-debug-ui-ignore',
      transparent: true,
      circle: true,
      thin: true,
      title: svs.ui.icon.partials.icon({ icon: 'menu-right', class: 'js-debug-ui-ignore' }),
      linkTitle: 'Next component'
    });
    buttons += `</div>`;

    var text = '<a class="f-bold margin-xs-bottom inline-block" href="/ops/styleguide/' + component.path + '">' + component.name + '</a>';
    text += '<div class="margin-xs-bottom"><span class="f-bold">Settings:</span> ' +
      element.attr('class').split(' ').filter(function(c) {
        return c !== 'js-debug-ui-component' && c !== 'debug-ui-component' && c.startsWith(component.settingsPrefix);
      }).join(' ') + '</div>';
    if (component.name.toLowerCase() === 'grid') {
      var gutter = element.parents('[class*=gutter]');

      if (gutter.length) {
        text += '<div class="margin-xs-bottom"><span class="f-bold">Gutter:</span> ' + gutter.attr('class').split(' ').filter(function(c) {
          return c.startsWith('gutter');
        }).join(' ') + '</div>';
      }
    }
    text += '<div class="margin-xs-bottom">' +
      '<span class="f-bold">Element:</span> ' + element.prop('tagName').toLowerCase() +
      ' <span class="f-bold">Size:</span> ' + element.width() + ' x ' + element.height() +
      '</div>';
    text += '<div class="margin-xs-bottom"><span class="f-bold">Background:</span> ' + getColorName(element.css('background-color')) +
      '<span class="debug-ui-component-color" style="background: ' + element.css('background-color') + ';"></span></div>';
    text += '<div class="margin-xs-bottom"><span class="f-bold">Font:</span> ' + element.css('font') + ' ' + getColorName(element.css('color')) +
      '<span class="debug-ui-component-color" style="background: ' + element.css('color') + ';"></span></div>';

    return hasMultipleComponents ? buttons + text : text;
  }

  function openComponentPopup(element, component, hasMultipleComponents) {
    var componentPopupEl = $('.debug-ui-info-popup');

    componentPopupEl.html(getComponentMetaData(element, component, hasMultipleComponents));

    var top = element.offset().top - $(window).scrollTop() + element.outerHeight();
    var left = element.offset().left;

    if (top + componentPopupEl.outerHeight() > $(window).height()) {
      top = element.offset().top - $(window).scrollTop() - componentPopupEl.outerHeight();
    }

    if (left + componentPopupEl.outerWidth() > $(window).width()) {
      left = element.offset().left - ((left + componentPopupEl.outerWidth()) - $(window).width()) - 6;
    }

    componentPopupEl.css({
      top: top,
      left: left
    });
    componentPopupEl.show();
  }

  function setSizeLabel($item) {
    var src = $item.find('.responsive-image').get(0).currentSrc;
    var size;

    if (src.indexOf('-320.') > -1) {
      size = '320 x 140';
    } else if (src.indexOf('-480.') > -1) {
      size = '480 x 210';
    } else if (src.indexOf('-640.') > -1) {
      size = '640 x 280';
    } else if (src.indexOf('-800.') > -1) {
      size = '800 x 350';
    } else if (src.indexOf('-960.') > -1) {
      size = '960 x 420';
    } else if (src.indexOf('-1120.') > -1) {
      size = '1120 x 490';
    } else if (src.indexOf('-1280.') > -1) {
      size = '1280 x 560';
    }

    if (size !== undefined) {
      var $sizeLabel = $item.find('.debug-ui-responsive-image-size-label');
      if (!$sizeLabel.length) {
        $sizeLabel = $('<div/>', {
          class: 'debug-ui-responsive-image-size-label'
        }).appendTo($item);
      }
      $sizeLabel.text(size);
    }
  }

  function debugResponsiveImages() {
    $('.responsive-image-wrapper').each(function() {
      var $item = $(this);
      $item.find('.responsive-image').on('load', function() {
        setSizeLabel($item);
      });
    });
  }

  function listen() {
    var mutSrv = window.MutationObserver || window.WebKitMutationObserver;
    var evtSupp = window.addEventListener;
    var domChangeTimeoutIndex = null;
    var domChangeTimeout = 100;
    var rootEl = document.body;

    if (mutSrv) {
      var obs = new mutSrv(function(mutations) {
        var i = mutations.length;
        while (i) {
          i--;

          if (mutations[i].addedNodes) {
            i = 0;

            window.clearTimeout(domChangeTimeoutIndex);

            domChangeTimeoutIndex = window.setTimeout(function() {
              addDebugCSSClasses();
              debugResponsiveImages();
            }, domChangeTimeout);
          }
        }
      });
      obs.observe(rootEl, {
        childList: true,
        subtree: true,
        attributes: false
      });
    } else if (evtSupp) {
      document.body.addEventListener(
        'DOMNodeInserted',
        function() {
          window.clearTimeout(domChangeTimeoutIndex);

          domChangeTimeoutIndex = window.setTimeout(function() {
            addDebugCSSClasses();
            debugResponsiveImages();
          }, domChangeTimeout);
        },
        false
      );
    }
  }

  listen();
  addDebugCSSClasses();
  initToolbar();
  debugResponsiveImages();
  initComponentPopup();
})(svs);


 })(window);