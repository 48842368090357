(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/debug-ui/views/debug-ui-settings.js') >= 0) return;  svs.modules.push('/components/components/debug-ui/views/debug-ui-settings.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.debug_ui=_cmps.debug_ui||{};
_cmps.debug_ui.templates=_cmps.debug_ui.templates||{};
svs.components.debug_ui.templates.debug_ui_settings = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"list-group-item padding-xs-0\">\n          <label class=\"pointer block padding-xs-1\">\n            <span class=\"btn-label debug-ui-ignore\">\n              <input type=\"checkbox\" name=\"debug-ui-components[]\" class=\"js-debug-ui-component\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":19,"column":108},"end":{"line":19,"column":118}}}) : helper)))
    + "\">\n              <span class=\"btn-toggle btn-toggle-default btn-toggle-100 btn-toggle-checkmark\"></span>\n            </span>\n            <span class=\"align-middle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":47}}}) : helper)))
    + "</span>\n          </label>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"debug-ui panel panel-default debug-ui-ignore padding-xs-1\">\n  <div class=\"panel-body\">\n    <h2 class=\"f-prominent f-bold margin-xs-bottom-1\">Debug UI</h2>\n\n    <div class=\"panel panel-default js-expandable-box debug-ui-ignore\">\n      <div class=\"panel-heading js-expandable-box-header f-bold f-prominent pointer\">\n        Components\n        <i class=\"icon-default icon-menu-down js-expandable-box-icon-arrow expandable-box-icon right\"></i>\n      </div>\n      <ul class=\"list-group list-group-default debug-ui-components expandable-box-content-hide expandable-box-content js-expandable-box-content debug-ui-ignore\">\n        <li class=\"list-group-item\">\n          <button class=\"btn btn-default btn-100 js-debug-ui-components-all debug-ui-ignore\">All</button>\n          <button class=\"btn btn-default btn-100 js-debug-ui-components-none debug-ui-ignore\">None</button>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"components") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":25,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n\n    <div class=\"panel panel-default js-expandable-box debug-ui-ignore expandable-box-open\">\n      <div class=\"panel-heading js-expandable-box-header f-bold f-prominent pointer\">\n        Tools\n        <i class=\"icon-default icon-menu-down js-expandable-box-icon-arrow expandable-box-icon right\"></i>\n      </div>\n      <ul class=\"list-group list-group-default expandable-box-content-hide expandable-box-content js-expandable-box-content debug-ui-ignore\">\n        <li class=\"list-group-item padding-xs-0\">\n          <label class=\"pointer block padding-xs-1\">\n              <span class=\"btn-label debug-ui-ignore\">\n                <input type=\"checkbox\" name=\"debug-ui-tools[]\" class=\"js-debug-ui-tool-lines\">\n                <span class=\"btn-toggle btn-toggle-default btn-toggle-100 btn-toggle-checkmark\"></span>\n              </span>\n            <span class=\"align-middle\">Lines</span>\n          </label>\n        </li>\n        <li class=\"list-group-item padding-xs-0\">\n          <label class=\"pointer block padding-xs-1\">\n              <span class=\"btn-label debug-ui-ignore\">\n                <input type=\"checkbox\" name=\"debug-ui-tools[]\" class=\"js-debug-ui-tool-grid\">\n                <span class=\"btn-toggle btn-toggle-default btn-toggle-100 btn-toggle-checkmark\"></span>\n              </span>\n            <span class=\"align-middle\">Grid</span>\n          </label>\n        </li>\n      </ul>\n      </div>\n  </div>\n</div>";
},"useData":true});
Handlebars.partials['components-components-debug_ui-debug_ui_settings'] = svs.components.debug_ui.templates.debug_ui_settings;
})(svs, Handlebars);


 })(window);